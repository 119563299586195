import React from 'react';
import './App.css'

const App = () => {
    return (
        <div className='App'>
            <h2>Widgets:</h2>
            <a href='exchange-without-redirect.html'>exchange-without-redirect</a>
            <a href='exchange-with-redirect.html'>exchange-with-redirect</a>
            <a href='exchange_js.html'>exchange-js</a>
            <a href='exchange_js_2.html'>exchange-js-2</a>
            <a href='commodities-with-redirect.html'>commodities-with-redirect</a>
            <a href='commodities-without-redirect.html'>commodities-without-redirect</a>
            <a href='commodities-without-redirect-simple-view.html'>commodities-without-redirect-simple-view</a>
            <a href='commodities-without-redirect-new-flow.html'>commodities-without-redirect-new-flow</a>
            <a href='eden-donate-without-redirect.html'>eden-donate-without-redirect</a>
            <a href='donate-with-redirect.html'>donate-with-redirect</a>
            <a href='givebit.html'>givebit</a>
            <a href='exchange_start_by_js_2.0_preselected_chain_token.html'>exchange_start_by_js_2.0_preselected_chain_token</a>
            <a href='exchange_start_by_js_2.0_preselected_chain_token_wallet.html'>exchange_start_by_js_2.0_preselected_chain_token_wallet</a>
            <a href='exchange_start_by_js_2.0_preselected_chain_token_wallet_base_usdc.html'>exchange_start_by_js_2.0_preselected_chain_token_wallet_base_usdc</a>
            <a href='exchange_start_by_js_custom_param.html'>exchange_start_by_js_custom_param</a>
            <a href='exchange_start_by_js_preselected_chain_token_wallet.html'>exchange_start_by_js_preselected_chain_token_wallet</a>
        </div>
    );
};

export default App;
